import React from "react";
import "../theory/lecture.css";

const Lecture2 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Квантовая система</h3>
      <p>
        Для квантовых вычислений как правило требуется больше одного кубита.
        Система, состоящая из нескольких кубитов, представляет собой тензорное
        произведение составляющих ее систем. Такая система называется{" "}
        <i>квантовой системой</i>.
      </p>
      <p>
        Состояние квантовой системы, которая состоит из <i>n</i> кубитов можно
        представить следующим выражением:
      </p>
      <p align="center">
        |q<sub>1</sub>&#9002; ... |q<sub>n</sub>&#9002; = (α<sub>0</sub>
        |0&#9002; + β<sub>0</sub>|1&#9002;) &#8855; (α<sub>1</sub>|0&#9002; + β
        <sub>1</sub>|1&#9002;) &#8855; ... &#8855; (α<sub>n-1</sub>|0&#9002; + β
        <sub>n-1</sub>|1&#9002;)
      </p>
      <p>
        Приведем пример для системы двух кубитов. В данном случае мы имеем
        четырехмерный вектор единичной длины. Полностью смешанное состояние
        системы двух кубитов можно описать следующим образом:
      </p>
      <p align="center">
        |&#936;&#9002; = (α<sub>0</sub>|0&#9002; + β<sub>0</sub>|1&#9002;)
        &#8855; (α<sub>1</sub>|0&#9002; + β<sub>1</sub>|1&#9002;) = α
        <sub>0</sub>α<sub>1</sub>|00&#9002; + α<sub>0</sub>β<sub>1</sub>
        |01&#9002; + β<sub>0</sub>α<sub>1</sub>|10&#9002; + β<sub>0</sub>β
        <sub>1</sub>|11&#9002;.
      </p>
      <p>
        При этом сумма вероятностей нахождения в том или ином состоянии
        по-прежнему равна 1.
      </p>
      <p align="center">
        |α<sub>0</sub>α<sub>1</sub>|<sup>2</sup> + |α<sub>0</sub>β<sub>1</sub>|
        <sup>2</sup> + |β<sub>0</sub>α<sub>1</sub>|<sup>2</sup> + |β<sub>0</sub>
        β<sub>1</sub>|<sup>2</sup>= 1.
      </p>
      <p>
        Как и в бинарном случае, этот набор возможных результатов называется
        измерительным базисом, а приводящие к ним комбинации значений –
        базисными состояниями. Тогда любое системное квантовое состояние мы
        можем записать как суперпозицию базисных состояний:
      </p>
      <p align="center">
        |&#936;&#9002; = α<sub>0</sub>|00&#9002; + α<sub>1</sub>|01&#9002; + α
        <sub>2</sub>|10&#9002; + α<sub>3</sub>|11&#9002;
      </p>
      <p>
        Физический смысл коэффициентов, стоящих в формуле перед базисными
        состояниями тот же, что и для одного кубита – это пропорция вариантов
        значений. Однако, в отличии от одного кубита, это значения не одиночного
        измерения, а комбинация двух измерений.
      </p>
      <p>
        Таким образом, квантовая система из двух кубитов может находится в одном
        из четырех состояний:{" "}
      </p>
      <ul>
        <li>
          <p>|00&#9002; – оба кубита при измерении дают результат |0&#9002;.</p>
        </li>
        <li>
          <p>
            |01&#9002; – первый кубит при измерении даёт |0&#9002;, второй кубит
            даёт |1&#9002;.
          </p>
        </li>
        <li>
          <p>
            |10&#9002; – первый кубит при измерении даёт |1&#9002;, второй кубит
            даёт |0&#9002;.
          </p>
        </li>
        <li>
          <p>|11&#9002; – оба кубита при измерении дают результат |1&#9002;.</p>
        </li>
      </ul>
      <p>
        Другими словами, вектор состояния <i>n</i>-кубитной системы существует в
        2
        <sup>
          <i>n</i>
        </sup>
        -мерном комплексном пространстве и представляет собой сумму 2
        <sup>
          <i>n</i>
        </sup>{" "}
        базисных векторов – базисных состояний.
      </p>
      <p>
        Вероятностная природа квантовой механики проявляется в процессе
        измерений. Измерение является единственным способом извлечения данных,
        определяющих квантовое состояние. В результате измерения кубит
        немедленно коллапсирует. Допустим, одномерный кубит находится в
        состоянии суперпозиции. Если его измерить, то он примет одно конкретное
        значение – |0&#9002; или |1&#9002;. После измерения кубита коэффициенты
        α и β, которыми характеризовалось его предыдущее состояние, будут
        утеряны.
      </p>
      <p>
        Рассмотрим <i>n-кубит</i>:
      </p>
      <div align="center">
        <p className="lecture2-formula-1"></p>
      </div>
      <p>Так как длина вектора остается равной единицы, то можно записать</p>
      <div align="center">
        <p className="lecture2-formula-2"></p>
      </div>
      <p>
        Когда мы будем выполнять измерение состояния такого кубита, то получим
        одно из классических значений совокупности <i>n</i>
        битов от 000...0 до 111...1, где значение <i>k</i> (в двоичном
        представлении) появится с вероятностью |α
        <sub>
          <i>k</i>
        </sub>
        |<sup>2</sup>.{" "}
        <b>
          Следует отметить, что для каких-то состояний вероятность может
          оказаться нулевой!
        </b>
      </p>
    </div>
  );
};

export default Lecture2;
