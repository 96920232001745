import React from "react";
import "../theory/lecture.css";

const Lecture19 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Алгоритм Шора</h3>
      <p>
        Алгоритм Шора является одним из наиболее распространенных алгоритмов и
        направлен на решение задачи факторизации. Факторизация – это разложение
        чисел на множители. Факторизация широко применяется в современной теории
        чисел и криптоанализа. Следует отметить, что эта задача весьма сложная,
        так как трудоемкость классических алгоритмов растет экспоненциально с
        увеличением размера факторизуемых чисел. Так, при фактризации числа{" "}
        <i>N</i> путем простого перебора необходимо будет перебрать все варианты
        от 2 до &radic;<i>N</i>, а это весьма сложно если мы имеем дело с очень
        большими числами.{" "}
      </p>
      <p>
        Пример использования факторизации – это алгоритмы шифрования с открытым
        ключом (<i>RSA</i>, <i>El Gamal</i> и т.д.). В данном случае ключ
        представляет собой пару больших чисел, а взлом шифра, который
        заключается в нахождении по открытому ключу приватного и наоборот,
        требует решения задачи факторизации.{" "}
      </p>
      <p>
        Алгоритм Шора позволяет выполнить решение задачи факторизации за
        полиномиальное время. Это осуществляется за счет использования свойства
        квантового параллелизма и сведения задачи к поиску периода функции.{" "}
      </p>
      <p>
        Допустим нам необходимо разложить на множители некоторое число <i>N</i>.
        Изначально выберем произвольное число <i>a</i> &lt; <i>N</i> и
        рассматриваем функцию{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>) ={" "}
        <i>
          a
          <sup>
            <i>x</i>
          </sup>
        </i>
        mod<i>N</i>.
      </p>
      <p>
        Функция{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>) является периодической с периодом <i>r</i>. Период <i>r</i>{" "}
        является порядком числа <i>a</i>: <i>a</i>
        <sup>
          <i>r</i>
        </sup>{" "}
        = 1 mod <i>N</i> и &forall;<i>r</i>
        <sub>1</sub> &lt; <i>r</i> &rarr; <i>a</i>
        <sup>
          <i>
            r<sub>i</sub>
          </i>
        </sup>{" "}
        &ne; 1 mod <i>N</i>. Если число <i>N</i>простое, то период <i>r</i>{" "}
        будет равно <i>N</i> - 1. Этот случай весьма простой и легко реализуется
        проверкой на простоту классическими методами. В общем случае{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x + r</i>) ={" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>). Если период <i>r</i>известен, то разложение на множители
        числа <i>N</i> легко можно определить классическими методами. В
        частности, если период <i>r</i> является четным числом, то из
        соотношения{" "}
        <i>
          a
          <sup>
            <i>r</i>
          </sup>
        </i>{" "}
        - 1 = 0 mod <i>N</i> можно записать:
      </p>
      <p align="center">
        {" "}
        (<i>a</i>
        <sup>
          <i>r/2</i>
        </sup>
        - 1)(<i>a</i>
        <sup>
          <i>r/2</i>
        </sup>
        + 1) = 0 mod <i>N</i>
      </p>
      <p>
        Так как (<i>a</i>
        <sup>
          <i>r/2</i>
        </sup>
        - 1)(<i>a</i>
        <sup>
          <i>r/2</i>
        </sup>
        + 1) делится на <i>N</i>, то оба сомножителя имеют общие с <i>N</i>
        делители. Эти делители можно определить классическим алгоритмом Евклида
        по поиску наибольшего общего делителя. Если же период <i>r</i> является
        нечетным или (<i>a</i>
        <sup>
          <i>r/2</i>
        </sup>
        - 1)(<i>a</i>
        <sup>
          <i>r/2</i>
        </sup>
        + 1) вырождается в ноль, то следует выбрать другое число <i>a</i>. Для
        больших чисел <i>N</i> это случается редко.
      </p>
      <p>
        Квантовый алгоритм Шора предназначен для быстрого поиска периода{" "}
        <i>r</i>. Для реализации алгоритма необходимо использовать квантовый
        компьютер с двумя квантовыми регистрами размера <i>n</i>. Причем размер
        должен быть таким, что <i>M</i> = 2
        <sup>
          <i>n</i>
        </sup>{" "}
        &gt; <i>N</i>, <i>M</i> &asymp;{" "}
        <i>
          N<sup>2</sup>
        </i>
        . Алгоритм определения периода <i>r</i> будет следующим:
      </p>
      <p>
        1. На первом этапе необходимо приготовить два входных регистра в
        состоянии |0&#9002;.
      </p>
      <p>
        2. Далее воздействием на каждый кубит входного регистра преобразованием
        Адамара:
      </p>
      <div align="center">
        <p className="lecture19-formula-1"></p>
      </div>
      <p>
        3. Применим к обоим регистрам квантовую схему, реализующую функцию{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>). Теперь регистры перейдут в состояние:
      </p>
      <div align="center">
        <p className="lecture19-formula-2"></p>
      </div>
      <p>
        Таким образом, в выходном регистре будет суперпозиция всех возможных
        значений функции{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>).{" "}
      </p>
      <p>
        4. Далее производится измерение выходного регистра. В результате
        измерений мы получим
      </p>
      <div align="center">
        <p className="lecture19-formula-3"></p>
      </div>
      <p>
        После измерения мы получим значение функции{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>k</i>)при некотором случайном значении <i>k</i>, а выходной регистр
        перейдет в состояние |
        <i>
          f<sub>a</sub>
        </i>
        (<i>k</i>)&#9002;. Измеренное значение выходного регистра нас не
        интересует, но важно, что состояние входного регистра редуцируется до
        комбинации только тех состояний, которые совместимы с измеренным на
        выходе значением:{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>) ={" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>k</i>), <i>x</i> = <i>k</i>, <i>x</i> = <i>k</i> + <i>r</i>,{" "}
        <i>x</i> = <i>k</i> + 2<i>r</i> и т.д.
      </p>
      <p>
        5. Для извлечения периодичности в выходном регистре необходимо выполнить
        квантовое преобразование Фурье и измерение результата преобразования.
      </p>
      <div align="center">
        <p className="lecture19-formula-4"></p>
      </div>
      <p>
        При преобразовании Фурье период <i>r</i> будет преобразован в <i>M</i> /{" "}
        <i>r</i> и измерение приведет к тому, что с высокой долей вероятности мы
        получим <i>jM</i>/<i>r</i> для <i>j</i> = 1, 2, ... Далее, применив
        классический алгоритм разложения в непрерывную дробь (алгоритм Евклида)
        можно извлечь из полученного результата период <i>r</i>.
      </p>
      <p>На рис. 1 представлена структурная схема алгоритма Шора.</p>
      <div align="center">
        <p className="lecture19-formula-5"></p>
      </div>
      <p>Приведем классический пример реализации алгоритма Шора.</p>
      <p>
        <b>Пример 3.1</b>: Допустим нам необходимо выполнить факторизацию числа{" "}
        <i>N = pq = 3&middot;5 = 15</i>. Используя функцию Эйлера можно
        определить функцию{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>):
      </p>
      <p>
        Ф(<i>N</i>) = (<i>q</i> - 1)(<i>p</i> - 1) = (5 - 1)(3 - 1) = 8 &rarr;{" "}
        <i>a</i> = 7 &rarr;{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>) = 7
        <sup>
          <i>x</i>
        </sup>{" "}
        mod15.
      </p>
      <p>
        Функцию{" "}
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>) можно записать в следующем виде:
      </p>
      <p align="center">
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>) = (7<sup>8</sup>)
        <sup>
          <i>
            x<sub>3</sub>
          </i>
        </sup>{" "}
        &middot; (7<sup>4</sup>)
        <sup>
          <i>
            x<sub>2</sub>
          </i>
        </sup>{" "}
        &middot; (7<sup>2</sup>)
        <sup>
          <i>
            x<sub>1</sub>
          </i>
        </sup>{" "}
        &middot; (7<sup>1</sup>)
        <sup>
          <i>
            x<sub>0</sub>
          </i>
        </sup>{" "}
        mod15
      </p>
      <p>
        Поскольку (7<sup>8</sup>)
        <sup>
          <i>
            x<sub>3</sub>
          </i>
        </sup>
        mod15 = 1, (7<sup>4</sup>)
        <sup>
          <i>
            x<sub>2</sub>
          </i>
        </sup>
        mod15 = 1 и (7<sup>2</sup>)
        <sup>
          <i>
            x<sub>1</sub>
          </i>
        </sup>
        mod15 = (4)
        <sup>
          <i>
            x<sub>1</sub>
          </i>
        </sup>
        mod15 то можно переопределить функцию как:
      </p>
      <p>
        <i>
          f<sub>a</sub>
        </i>
        (<i>x</i>) = (4)
        <sup>
          <i>
            x<sub>1</sub>
          </i>
        </sup>{" "}
        &middot; (7)
        <sup>
          <i>
            x<sub>0</sub>
          </i>
        </sup>
        mod15{" "}
      </p>
      <p>
        На рис. 2 представлена квантовая схема, реализующая алгоритм Шора для
        данного примера.
      </p>
      <div align="center">
        <p className="lecture19-formula-6"></p>
      </div>
      <p align="center">
        Рис. 2. Квантовая схема алгоритма Шора для <i>N</i> = 15
      </p>
      <p>
        На рис. 2 блок под номером <b>1</b> реализует умножение регистра |
        <i>y</i>&#9002; на 7, а блок под номером <b>2</b> – умножение на 4.
        Далее выполняется квантовое преобразование Фурье (блок <i>QFT</i>) и
        измерение результата преобразования.
      </p>
      <p>
        Выполним моделирование данной схемы в системе IBM Quantum Experience.
        Результаты серии измерений представлены на рис. 3.
      </p>
      <div align="center">
        <p className="lecture19-formula-7"></p>
      </div>
      <p align="center">
        Рис. 3. Результаты симуляции алгоритма Шора для <i>N</i> = 15
      </p>
      <p>
        Из рис. 3 видно, что в результате измерений мы получили 0, 4, 8 и 12.
        Отбросив нулевой результат и вспомнив, что преобразование Фурье приведут
        к тому, что с высокой долей вероятности мы получим <i>jM/r</i>для{" "}
        <i>j</i> = 1, 2, ... можно вычислить период <i>r</i>:
      </p>
      <div align="center">
        <p className="lecture19-formula-8"></p>
      </div>
      <p>
        Таким образом, мы определили период <i>r</i> = 4. Зная период, мы можем
        определить числа <i>p</i> и <i>q</i>:
      </p>
      <p align="center">
        НОД(7<sup>r/2</sup> + 1,15) = НОД(50,15) = 5,
      </p>
      <p align="center">
        НОД(7<sup>r/2</sup> - 1,15) = НОД(49,15) = 3.
      </p>
    </div>
  );
};

export default Lecture19;
