import React from "react";
import "../theory/lecture.css";

const Lecture15 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Квантовая телепортация</h3>
      <p>
        Задача квантовой телепортации заключается в переносе неизвестного
        квантового состояния с одной системы на другую с использованием
        квантового канала связи. Так как квантовое состояние не может быть
        скопировано (теорема о неклонируемости), то в процессе передачи исходное
        состояние кубита будет утеряно. При осуществлении квантовой телепортации
        исходное состояние кубита будет утеряно, но при этом оно будет
        воссоздано у получателя.
      </p>
      <p>
        Рассмотрим пример квантовой телепортации. Допустим Алиса хочет передать
        Бобу кубит в состоянии суперпозиции |&#936;<sub>1</sub>&#9002; =<i>α</i>
        |0&#9002; + <i>&beta;</i>|1&#9002;. Кубит в данном состоянии находится у
        Алисы.
      </p>
      <p>
        Для организации передачи информации между Бобом и Алисой каждому из них
        пересылается один из двух кубитов приготовленных в запутанном состоянии,
        например,
      </p>
      <div align="center">
        <p className="lecture15-formula-1"></p>
      </div>
      <p>На рис. 1 показано распределение кубитов между Бобом и Алисой.</p>
      <div align="center">
        <p className="lecture15-formula-2"></p>
      </div>
      <p align="center">
        Рис. 1. Кубиты Алисы и Боба для квантовой телепортации
      </p>
      <p>Таким образом можно говорить, что мы имеем трехкубитовую систему:</p>
      <div align="center">
        <p className="lecture15-formula-3"></p>
      </div>
      <p>
        Далее Алиса применяет операцию контролируемой инверсии, причем
        передаваемый кубит |&#936;<sub>1</sub>&#9002; =<i>α</i>
        |0&#9002; + <i>&beta;</i>|1&#9002; будет являться контролирующим. Тогда
        трехбитовая система будет иметь вид:
      </p>
      <div align="center">
        <p className="lecture15-formula-4"></p>
      </div>
      <p>
        После операции{" "}
        <i>
          <b>CNOT</b>
        </i>{" "}
        Алиса выполняет преобразование Адамара с передаваемым кубитом. Для
        дальнейшего описания системы вспомним, что{" "}
      </p>
      <div align="center">
        <p className="lecture15-formula-5"></p>
      </div>
      <p>
        Следовательно, состояние системы после преобразования Адамара можно
        представить в виде:
      </p>
      <div align="center">
        <p className="lecture15-formula-6"></p>
      </div>
      <p>
        Напомним, что в данной системе два кубита принадлежат Алисе и один кубит
        – Бобу. Если выделить в волновой функции системы кубиты Алисы, то ее
        можно записать следующим образом:
      </p>
      <div align="center">
        <p className="lecture15-formula-7"></p>
      </div>
      <p>
        Из представленной выше функции можно заметить, что кубит, который
        принадлежит Бобу, может быть преобразован в исходный (передаваемый)
        кубит. Причем необходимое преобразование зависит от значений двух
        кубитов Алисы. Распишем необходимые преобразования для получения Бобом
        исходного кубита:
      </p>
      <p>
        1. Если кубиты Алисы при измерении принимают значения |00&#9002;, то в
        данном случае Бобу не нужно выполнять никаких преобразований и его кубит
        будет иметь волновую функцию <i>α</i>
        |0&#9002; + <i>&beta;</i>|1&#9002;.
      </p>
      <p>
        2. Если кубиты Алисы при измерении принимают значения |01&#9002;, то в
        данном случае кубит, который принадлежит Бобу находится в состоянии{" "}
        <i>α</i>
        |1&#9002; + <i>&beta;</i>|0&#9002;. Можно заметить, что для получения
        исходного кубита Бобу необходимо выполнить операцию инверсии:
      </p>
      <div align="center">
        <p className="lecture15-formula-8"></p>
      </div>
      <p>
        3. Если кубиты Алисы при измерении принимают значения |10&#9002;, то в
        данном случае кубит, который принадлежит Бобу находится в состоянии
        |0&#9002; - <i>&beta;</i>|1&#9002;. Можно заметить, что для получения
        исходного кубита Бобу необходимо применить гейт{" "}
        <i>
          <b>Z</b>
        </i>
        :
      </p>
      <div align="center">
        <p className="lecture15-formula-9"></p>
      </div>
      <p>
        4. Если кубиты Алисы при измерении принимают значения |11&#9002;, то в
        данном случае кубит, который принадлежит Бобу находится в состоянии
        |1&#9002; - <i>&beta;</i>|0&#9002;. Можно заметить, что для получения
        исходного кубита Бобу необходимо применить гейты{" "}
        <i>
          <b>Z</b>
        </i>{" "}
        и{" "}
        <i>
          <b>X</b>
        </i>
        :
      </p>
      <div align="center">
        <p className="lecture15-formula-10"></p>
      </div>
      <p>
        Исходя из алгоритма телепортации можно построить квантовые схемы ее
        реализующие (рис. 1).{" "}
      </p>
      <div align="center">
        <p className="lecture15-formula-11"></p>
      </div>
      <p align="center">Рис. 1. Схема алгоритма квантовой телепортации</p>
      <p>
        В представленной на рис. 1 схеме передаваемым кубитом является |&#936;
        <sub>1</sub>&#9002;. При разработке схемы телепортации данный кубит
        должен находится в состоянии суперпозиции. Получить подобное состояние
        можно с использованием гейтов поворота{" "}
        <i>
          <b>U</b>
        </i>{" "}
        или{" "}
        <i>
          <b>R</b>
        </i>
        .
      </p>
    </div>
  );
};

export default Lecture15;
