import React from "react";
import "../theory/lecture.css";

const Lecture5 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Оператор NOT (Гейт X)</h3>
      <p>
        В классических дискретных вычислениях оператор <i>NOT</i> – это оператор
        инверсии. В соответствии с этим определением классического оператора
        <i> NOT</i>, квантовый{" "}
        <i>
          <b>гейт X</b>
        </i>{" "}
        (т.е. гейт преобразующий информацию внутри кубита) может быть определен
        по аналогии:
      </p>
      <p align="center">
        |&#936;&#9002; = α|0&#9002; + β|1&#9002; &#8658;
        <i> NOT </i>|&#936;&#9002; = α|1&#9002; + β|0&#9002;
      </p>
      <p>
        Квантовым аналогом классического оператора <i> NOT</i> является матрица
        вида:
      </p>
      <p align="center">
        <p className="lecture5-formula-1"></p>
      </p>
      <p>
        Покажем действия{" "}
        <i>
          <b>гейта X</b>
        </i>{" "}
        на кубит в различных состояниях.
      </p>
      <p align="center">
        <p className="lecture5-formula-2"></p>
      </p>

      <p align="center">
        <p className="lecture5-formula-3"></p>
      </p>
      <p align="center">
        <i>a</i>
      </p>
      <p align="center">
        <p className="lecture5-formula-4"></p>
      </p>
      <p align="center">
        <i>б</i>
      </p>
      <p align="center">
        Рис.1. Действие
        <i>
          <b> гейта X</b>
        </i>
        : а) начальное состояние кубита |0&#9002;, б) начальное состояние кубита
        |1&#9002;{" "}
      </p>
      <p>
        С точки зрения интерпретации действия данного гейта на состояние{" "}
        <i>
          <b>кубита</b>
        </i>
        с помощью сферы Блоха, можно заметcить, что происходит поворот вектора
        состояния на 180 градусов вокруг оси Х (рис. 1).
      </p>
    </div>
  );
};

export default Lecture5;
