import React from "react";
import "../theory/lecture.css";

const Lecture17 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Алгорит Гровера</h3>
      <p>
        Алгоритм Гровера направлен на решение следующей задачи: в базе
        неупорядоченных данных, состоящей из <i>n</i> элементов, требуется найти
        элемент с заданными свойствами. Для решения данной задачи на
        классическом компьютере в среднем потребуется перебрать <i>n</i>/2
        элементов базы, а в наихудшем случае – <i>n</i>-1 элементов. Квантовый
        алгоритм Гровера позволит выполнить данную задачу всего за &radic;
        <i>n</i> операций.
      </p>
      <p>
        Задачу поиска можно сформулировать следующим образом: пусть имеется
        бинарная функция <i>f</i> : &#123;0,1&#125;
        <sup>
          <i>n</i>
        </sup>{" "}
        &rarr; &#123;0,1&#125;, и известно, что данная функция принимает
        значение 0 на всех входных наборах, кроме <i>x</i>
        <sub>0</sub>, и задача состоит в том, чтобы найти <i>x</i>
        <sub>0</sub>. Очевидно, что для реализации алгоритма поиска необходимо
        получит оракул данной функции:
      </p>
      <div align="center">
        <p className="lecture17-formula-1"></p>
      </div>
      <p>
        Вычисление функции <i>f</i>(<i>x</i>) для всевозможных входных наборов |
        <i>x</i>&#9002; за счет применения оператора{" "}
        <i>
          U<sub>f</sub>
        </i>
        , при начальном значении выходного регистра равному 0, приведет к
        суперпозиции{" "}
      </p>
      <div align="center">
        <p className="lecture17-formula-2"></p>
      </div>
      <p>
        Для входного набора <i>x</i>
        <sub>0</sub>, при котором <i>f</i>(<i>x</i>
        <sub>0</sub>) = 1, состояние |<i>x</i>
        <sub>0</sub>&#9002;|1&#9002; будет иметь вероятность 2
        <sup>
          -<i>n</i>
        </sup>
        . Суть алгоритма Гровера состоит в том, чтобы максимально повысить
        амплитуду состояния |<i>x</i>
        <sub>0</sub>&#9002;|1&#9002; и уменьшить амплитуды состояний |<i>x</i>
        <sub>0</sub>&#9002;|0&#9002;. Представим последовательность реализации
        алгоритма Гровера:
      </p>
      <p>
        1. Подготавливаем входной регистр |<i>x</i>
        <sup>
          <i>n</i>
        </sup>
        &#9002;, который будет содержать суперпозицию всех возможных значений
        входных наборов.
      </p>
      <p>
        2. Вычисляем функцию <i>f</i>(<i>x</i>).
      </p>
      <p>
        3. Изменяем знак коэффициентов для тех значений <i>x</i>при которых
        функция <i>f</i>(<i>x</i>) равна 1.
      </p>
      <p>
        4. Далее необходимо увеличить амплитуду коэффициентов тех значений{" "}
        <i>x</i> при которых функция <i>f</i>(<i>x</i>) равна 1. Увеличение
        амплитуд возможно за счет применения операции, которая носит название
        «инверсия относительно среднего». После применения данной операции
        амплитуды входных значений, при которых функция равна 1 вырастут, а
        амплитуды входных значений, при которых функция равно 0 – уменьшаться.
      </p>
      <p>
        5. Далее пункты 2-4 повторяются раз &pi;/4 &radic;2<sup>n</sup>.
      </p>
      <p>
        Изменение знака коэффициентов выполняется следующим образом. Возьмем
        значение выходного регистра равным 1 и применим преобразование Адамара:
      </p>
      <div align="center">
        <p className="lecture17-formula-3"></p>
      </div>
      <p>
        Затем к полученному состоянию суперпозиции применяется преобразование{" "}
        <i>
          U<sub>f</sub>
        </i>
        и, как и в алгоритме Дойча, это приводит к следующему результату:
      </p>
      <div align="center">
        <p className="lecture17-formula-4"></p>
      </div>
      <p>
        Отсюда можно заметить, что в полученном состоянии суперпозиции амплитуды
        значений, при которых функция равна 1, будут иметь отрицательное
        значение.
      </p>
      <p>
        Инверсия относительно среднего – это унитарное преобразование, которое
        воздействует на систему следующим образом:
      </p>
      <div align="center">
        <p className="lecture17-formula-5"></p>
      </div>
      <p>
        где <i>A</i> – среднее значение амплитуды. По сути амплитуды,
        осуществляется путем переворачивания вероятностей относительно их
        среднего. Если число выше среднего, оно переворачивается и становится
        ниже среднего и наоборот.
      </p>
      <p>Преобразование инверсии относительно среднего описывается матрицей:</p>
      <div align="center">
        <p className="lecture17-formula-6"></p>
      </div>
      <p>
        где <i>N</i> = 2
        <sup>
          <i>n</i>
        </sup>
      </p>
      <p>
        Пример матрицы преобразования «инверсия относительно среднего» для{" "}
        <i>N</i> = 4, т.е. для ,<i>n</i> = 2, будет выглядеть следующим образом:
      </p>
      <div align="center">
        <p className="lecture17-formula-7"></p>
      </div>
      <p>
        Пример схемы, реализующей данное преобразование представлен на рис. 1.
      </p>
      <div align="center">
        <p className="lecture17-formula-8"></p>
      </div>
      <p align="center">
        Рис. 1. Квантовая схема инверсии относительно среднего
      </p>
      <p>
        Покажем, что данная схема реализует преобразование «инверсия
        относительно среднего:
      </p>
      <div align="center">
        <p className="lecture17-formula-9"></p>
      </div>
      <p>
        В общем виде пример схемы алгоритма Гровера можно представить, как
        показано на рис. 2.
      </p>
      <div align="center">
        <p className="lecture17-formula-10"></p>
      </div>
      <p align="center">Рис 2. Схема алгоритма Гровера</p>
    </div>
  );
};

export default Lecture17;
