import React from "react";
import "../theory/lecture.css";

const Lecture1 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Квантовый бит</h3>
      <p>
        Единицей хранения информации является <i>бит</i>. Ячейка памяти
        классического компьютера объёмом в 1 бит может находиться в одном из
        двух различных состояниях. Эти состояния принято обозначать 0 и 1, саму
        такую ячейку также принято называть <i>битом</i>. Если бит находится в
        состоянии 0, то говорят, что он хранит значение 0, если же он находится
        в состоянии 1, то говорят, что он хранит значение 1.
      </p>
      <p>
        При выполнении вычислений над битами можно совершать различные двоичные
        операции, например такие:
      </p>
      <ul>
        <li>
          отрицание (NOT): y = <span className="overline">x</span>
        </li>
        <table align="center">
          <tbody>
            <tr>
              <th>x</th>
              <th>y</th>
            </tr>
            <tr>
              <td>0</td>
              <td>1</td>
            </tr>
            <tr>
              <td>1</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
        <li>
          конъюнкция («И», AND): y = x <sub>1</sub> &and; x <sub>2</sub>
        </li>
        <table align="center">
          <tbody>
            <tr>
              <th>
                x<sub>1</sub>
              </th>
              <th>
                x<sub>2</sub>
              </th>
              <th>y</th>
            </tr>
            <tr>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>0</td>
              <td>1</td>
              <td>0</td>
            </tr>
            <tr>
              <td>1</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>1</td>
              <td>1</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
        <li>
          дизъюнкция («ИЛИ», OR): y = x <sub>1</sub> &or; x <sub>2</sub>
        </li>
        <table align="center">
          <tbody>
            <tr>
              <th>
                x<sub>1</sub>
              </th>
              <th>
                x<sub>2</sub>
              </th>
              <th>y</th>
            </tr>
            <tr>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>0</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>1</td>
              <td>0</td>
              <td>1</td>
            </tr>
            <tr>
              <td>1</td>
              <td>1</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      </ul>
      <p>
        Данные базовые операции являются основой любых дискретных вычислений.
        Любая более сложная логическая операция – это определённая комбинация
        базовых операций. С использованием набора базовых побитовых операций
        работает обычный компьютер.
      </p>
      <p>
        <b>
          <i>Квантовый компьютер</i>
        </b>{" "}
        – это средство вычислительной техники в основе которого лежат законы
        квантовой механики. В квантовых компьютерах для вычисления применяются
        так называемые квантовые алгоритмы, которые используют эффекты квантовой
        механики, например, квантовый параллелизм и квантовая запутанность.
      </p>
      <p>
        Квантовый компьютер оперирует так называемыми квантовыми битами. Можно
        определить квантовый бит, или сокращенно{" "}
        <b>
          <i>q-бит (кубит)</i>
        </b>
        , как квантово-механическую систему, имеющую два состояния,
        обозначаемых, соответственно, как |0&#9002; и |1&#9002; . Однако в
        отличие от классического случая в квантовой механике эти два состояния
        могут находиться в{" "}
        <b>
          <i>состоянии суперпозиции</i>
        </b>
        , то есть наиболее общее состояние квантового бита может быть записано
        как:
      </p>
      <p align="center">|&#936;&#9002; = α|0&#9002; + β|1&#9002;,</p>
      <p>
        где α и β – комплексные коэффициенты. Другими словами, можно сказать,
        что законы квантовой механики допускают другие значения{" "}
        <b>
          <i>кубита</i>
        </b>
        , которые называются состояниями суперпозиции. Таким образом состояние
        суперпозиции представляют собой значения между экстремумами 0 и 1, а
        квантовый бит может принимать бесконечно много значений.
      </p>
      <p>
        Для примера, проведем аналогию с выключателем света. Классический бит
        может принимать только одно из двух состояний – «включено» или
        «выключено» (рис. 1а,б). Кубиты похожи на светильник с возможностью
        регулировки яркости (рис. 1в).
      </p>
      <div>
        <div className="image">
          <div className="img11"></div>
          <div className="img12"></div>
          <div className="img13"></div>
        </div>
        <div className="image">
          <div style={{ width: "23%" }} align="center">
            a
          </div>
          <div style={{ width: "23%" }} align="center">
            б
          </div>
          <div style={{ width: "23%" }} align="center">
            в
          </div>
        </div>
        <p align="center"> Рис. 1. Различие бита и кубита</p>
      </div>
      <p>
        Кубит можно определить, как вектор единичной длины в двумерном
        гильбертовом пространстве над полем комплексных чисел. Состояния
        |0&#9002; и |1&#9002; вместе представляют собой базисные вектора. Как и
        все векторы, они указывают направление и имеют величину. Для записи двух
        состояний кубитов можно использовать обозначения{" "}
        <b>
          <i>бра</i>
        </b>{" "}
        &#9001; | и{" "}
        <b>
          <i>кет</i>
        </b>{" "}
        | &#9002; – обозначения Дирака. Векторы вида | &#9002; называются
        <b>
          <i> кет</i>
        </b>
        -векторами, а вида &#9001; |{" "}
        <b>
          <i> бра</i>
        </b>
        -векторами. Обозначения{" "}
        <b>
          <i> кет</i>
        </b>{" "}
        соответствует следующим векторам:
        <b>
          <i> Кет</i>
        </b>
        -вектора, соответствующие нулевому и единичному состоянию кубита будут
        иметь следующий вид:
      </p>

      <div align="center">
        <table className="table-forml">
          <tbody>
            <tr className="table-forml">
              <td className="table-forml" rowSpan="2">
                |0&#9002; ={" "}
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>0</td>
            </tr>
          </tbody>
        </table>{" "}
        и
        <table className="table-forml">
          <tbody>
            <tr className="table-forml">
              <td className="table-forml" rowSpan="2">
                |1&#9002; ={" "}
              </td>
              <td>0</td>
            </tr>
            <tr>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        При измерении состояния системы с волновой функцией |&#936;&#9002; =
        α|0&#9002; + β|1&#9002; , вероятность обнаружить ее в состоянии
        |0&#9002; равна α<sup>2</sup>, а вероятность обнаружить ее в состоянии
        |1&#9002; равна β<sup>2</sup>. Сумма этих вероятностей равна единице:
      </p>
      <p align="center">
        {" "}
        |α<sup>2</sup>| +| β<sup>2</sup>| = 1
      </p>
      <p>
        Данное соотношение называется{" "}
        <b>
          <i>условием нормализации.</i>
        </b>
      </p>
      <p>
        То есть формула для волновой функции |&#936;&#9002; описывает, в какой
        пропорции бесконечное множество всех вариантов значений квантового
        состояния |&#936;&#9002; содержит варианты базисных состояний |0&#9002;
        и |1&#9002;.
      </p>
      <p>
        Визуализация состояния кубита возможна с помощью специального
        инструмента, называемого сферой Блоха. Сфера Блоха – это сфера с
        единичным радиусом, при этом точка на ее поверхности соответствует
        состоянию кубита.
      </p>
      <p>
        Когда кубит находится в суперпозиции |0&#9002; и |1&#9002;, вектор будет
        располагаться между двумя этими точками на сфере (угол θ). Вращение
        вокруг оси{" "}
        <b>
          <i>Z</i>
        </b>{" "}
        описывается углом φ, и отвечает за изменение фазы кубита. Сфера Блоха
        показана на рис. 2.
      </p>
      <div align="center">
        <div className="img2"></div>
      </div>
      <p align="center">
        Рис. 2. Сфера Блоха. Состояние в верхней части сферы представляет
        |0&#9002;, а состояние в нижней части сферы представляет |1&#9002;
      </p>
      <p>
        Физические реализации подобной системы с двумя состояниями могут быть
        разнообразными:
      </p>
      <ul>
        <li>
          электрон или ядро со спином 1/2, ориентированным по или против
          направления магнитного поля;
        </li>
        <li>
          электрон или ядро со спином 1/2, ориентированным по или против
          направления магнитного поля;
        </li>
        <li>фотон с горизонтальной или вертикальной поляризацией;</li>
        <li>и т.д.</li>
      </ul>
    </div>
  );
};

export default Lecture1;
