import React from "react";
import "../theory/lecture.css";

const Lecture10 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Гейты поворота Rx, Ry, Rz</h3>
      <p>
        <i>
          <b>Гейт Rx</b>
        </i>{" "}
        на сфере Блоха соответствует вращению кубита вокруг оси <i>X</i> на
        заданный угол. В матричном виде данный гейт можно записать как:{" "}
      </p>
      <div align="center">
        <p className="lecture10-formula-1"></p>
      </div>
      <p>
        По аналогии с{" "}
        <i>
          <b>гейтом Rx</b>
        </i>
        ,{" "}
        <i>
          <b>гейты Ry</b>
        </i>{" "}
        и{" "}
        <i>
          <b>Rz</b>
        </i>{" "}
        соответствуют вращению{" "}
        <i>
          <b>кубита</b>
        </i>{" "}
        вокруг осей Y и Z. При этом их можно определить как:
      </p>
      <div align="center">
        <p className="lecture10-formula-2"></p>
      </div>
      <p>
        Используя гейты поворота{" "}
        <i>
          <b>R</b>
        </i>{" "}
        и унитарные гейты{" "}
        <i>
          <b>U</b>
        </i>
        , можно получать произвольные состояния суперпозиции |&#936;&#9002; =
        α|0&#9002; + β|1&#9002;. Приведем примеры получения различных состояний
        суперпозиции кубита.
      </p>
      <p>
        Пример 1: Получим кубит в состоянии суперпозиции{" "}
        <div align="center">
          <p className="lecture12-formula-37"></p>
        </div>
        применив воздействие гейта{" "}
        <i>
          <b>Rx</b>
        </i>{" "}
        к{" "}
        <i>
          <b>кубиту</b>
        </i>
        , который находится в исходном состоянии |0&#9002;
      </p>
      <div align="center">
        <p className="lecture12-formula-38"></p>
      </div>
      <p>
        Таким образом для получения необходимого состояния нужно рассчитать
        правильный угол поворота <i>&theta;</i>. Для этого решим уравнение:
      </p>
      <div align="center">
        <p className="lecture12-formula-39"></p>
      </div>
      <p>
        На рис. 1 представлена квантовая схема, реализующая подобное состояние
        суперпозиции и результат измерений состояния{" "}
        <i>
          <b>кубита</b>
        </i>{" "}
        с использованием системы IBM Quantum Experience.
      </p>
      <div align="center">
        <p className="lecture12-formula-40"></p>
      </div>
      <div className="image">
        {" "}
        <div style={{ width: "30%" }} align="center">
          a
        </div>
        <div style={{ width: "30%" }} align="center">
          б
        </div>
      </div>
      <p align="center">
        Рис. 1. Квантовая схема получения кубита в состоянии суперпозиции (а) и
        результат симуляции (б)
      </p>
      <p>
        Пример 2: Получим кубит в состоянии суперпозиции |&#936;&#9002; =
        0.6|0&#9002; + 0.8|1&#9002;, применив воздействие гейта{" "}
        <i>
          <b>Rx</b>
        </i>{" "}
        к кубиту, который находится в исходном состоянии |0&#9002;. Состояние
        |&#936;&#9002; = 0.6|0&#9002; + 0.8|1&#9002; соответствует тому, что
        данный кубит будет принимать значение |0&#9002; с вероятностью 36% и
        значение |1&#9002; с вероятностью 64%.
      </p>
      <p>Решим уравнение:</p>
      <div align="center">
        <p className="lecture12-formula-42"></p>
      </div>
      <p>
        На рис. 2 представлена квантовая схема, реализующая подобное состояние
        суперпозиции и результат измерений состояния{" "}
        <i>
          <b>кубита</b>
        </i>{" "}
        с использованием системы IBM Quantum Experience.
      </p>
      <div align="center">
        <p className="lecture12-formula-41"></p>
      </div>
      <div className="image">
        {" "}
        <div style={{ width: "30%" }} align="center">
          a
        </div>
        <div style={{ width: "30%" }} align="center">
          б
        </div>
      </div>
      <p align="center">
        Рис. 2. Квантовая схема получения кубита в состоянии суперпозиции
        |&#936;&#9002; = 0.6|0&#9002; + 0.8|1&#9002; (а) и результат симуляции
        (б)
      </p>
    </div>
  );
};

export default Lecture10;
