import React from "react";
import "../theory/lecture.css";

const Lecture16 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center"> Алгоритм Дойча. Задача Дойча-Джозы</h3>
      <p>
        Принцип работы квантового компьютера и преимущества квантовых вычислений
        наиболее просто показать на примере простейшего квантового алгоритма –
        алгоритма Дойча. Опишем задачу, которую решает данный алгоритм.
        Предположим, что у нас имеется «черный ящик», который вычисляет
        неизвестную нам функцию одной переменной – <i>f(x)</i>. Так как это
        функция одной переменной, то на вход можно подать 0 или 1 и на выходе
        также можно получить или 0, или 1. Функции одной переменной можно
        разделить на две группы: константный и сбалансированные. На выходе
        первых мы всегда будем получать постоянное значение 0 или 1 не зависимо
        от того, что подано на вход. Константных функций одной переменной
        являются функции вида: <i>f(x)</i> = 0 и <i>f(x)</i> = 1.
        Сбалансированными функциями одной переменной являются функции
        тождественного равенства и инверсии: <i>f(x)</i> = <i>x</i> и{" "}
        <i>f(x)</i> = <i>x&#175;</i>.
      </p>
      <p>
        Задача Дойча состоит в том, чтобы определить к какой из двух групп
        (константная или сбалансированная) относится функция, реализуемая
        «черным ящиком». Решение подобной задачи с использованием классического
        компьютера сводится к тому, что необходимо на вход схемы подать сначала
        0, а потом 1. Т.е. на классическом компьютере нам необходимо будет два
        раза вызвать функцию и измерить выходную реакцию. После двух данных
        операций мы однозначно можем идентифицировать не только к какой группе
        относится функция, но и вид данной функции. Если же мы на классическом
        компьютере вызовем функцию один раз, то не сможем определить даже
        группу, к которой она относится. Однако использование квантового
        компьютера позволит определить группу за один вызов функции.
      </p>
      <p>
        В квантовой системе в качестве функции в «черном ящике» должен быть
        реализован унитарный оператор <i>U</i>
        <sub>
          <i>f</i>
        </sub>
        . Данный оператор называется квантовым оракулом. Квантовый оракул – это
        многокубитный гейт, который соответствует бинарной функции, содержит в
        себе информацию о функции <i>f(x)</i> и позволяет одновременно вызвать
        её на всех возможных аргументах. Квантовый оракул определяется как
        преобразование <i>U</i>
        <sub>
          <i>f</i>
        </sub>
        |<i>x</i>&#9002;|<i>y</i>&#9002; = |<i>x</i>&#9002;|<i>y</i>&#8853;
        <i>f(x)</i>&#9002;, где, в общем случае, множество кубитов |<i>x</i>
        &#9002; несут в себе информацию об аргументах функции (остаются
        неизменными), а кубит |<i>y</i>&#9002; – результат. При этом размерность
        квантового оракула будет составлять{" "}
        <i>
          <b>n+1</b>
        </i>{" "}
        при размерности функции <i>f(x) – n</i> .
      </p>
      <p>
        Определив квантовый оракул как преобразование <i>U</i>
        <sub>
          <i>f</i>
        </sub>
        |<i>x</i>&#9002;|<i>y</i>
        &#9002; = |<i>x</i>&#9002;|<i>y</i>&#8853;
        <i>f(x)</i>&#9002; можно показать его воздействие на состояние{" "}
      </p>
      <div align="center">
        <p className="lecture16-formula-1"></p>
      </div>
      <p>
        Давайте получим матрицы и схемы оракулов для всех бинарных функций одной
        переменной: <i>f(x)</i> = 0, <i>f(x)</i> = 1, <i>f(x)</i> = <i>x</i> и{" "}
        <i>f(x)</i> = <i>x&#175;</i> .
      </p>
      <p>
        1. Функция <i>f(x)</i> = 0. В соответствии с воздействием |<i>x</i>
        &#9002;|<i>y</i>&#8853;
        <i>f(x)</i>&#9002; рассмотрим в какие состояния должны перейти все
        возможные базисные состояния регистра:
      </p>
      <div align="center">
        <p className="lecture16-formula-2"></p>
      </div>
      <p>
        Таким образом, матрицу оракула <i>U</i>
        <sub>
          <i>f</i>
        </sub>{" "}
        можно представить в виде:
      </p>
      <div align="center">
        <p className="lecture16-formula-3"></p>
      </div>
      <p>
        Исходя из полученной матрицы оракула можно представить его квантовую
        схему (рис. 1).
      </p>
      <div align="center">
        <p className="lecture16-formula-4"></p>
      </div>
      <p align="center">
        Рис. 1. Квантовая схема оракула функции <i>f(x)</i> = 0
      </p>
      <p>
        2. Функция <i>f(x)</i> = 1. Рассмотрим в какие состояния должны перейти
        все возможные базисные состояния регистра:
      </p>
      <div align="center">
        <p className="lecture16-formula-5"></p>
      </div>
      <p>
        Таким образом, матрицу оракула <i>U</i>
        <sub>
          <i>f</i>
        </sub>{" "}
        можно представить в виде:
      </p>
      <div align="center">
        <p className="lecture16-formula-6"></p>
      </div>
      <p>
        Как видно из полученной матрицы оракула можно заметить, что в данном
        случае схема будет представлять собой инверсию |<i>y</i>&#9002;(рис. 2).
      </p>
      <div align="center">
        <p className="lecture16-formula-7"></p>
      </div>
      <p align="center">
        Рис. 2. Квантовая схема оракула функции <i>f(x)</i> = 1
      </p>
      <p>
        3. Функция <i>f(x)</i> = x. Рассмотрим в какие состояния должны перейти
        все возможные базисные состояния регистра:
      </p>
      <div align="center">
        <p className="lecture16-formula-8"></p>
      </div>
      <p>
        Таким образом, матрицу оракула <i>U</i>
        <sub>
          <i>f</i>
        </sub>{" "}
        можно представить в виде:
      </p>
      <div align="center">
        <p className="lecture16-formula-9"></p>
      </div>
      <p>
        В данном случае квантовый оракул представляет собой контролируемый гейт
        <b>
          <i>X</i>
        </b>
        , причем кубит |<i>x</i>&#9002; является контролирующим (рис. 3).
      </p>
      <div align="center">
        <p className="lecture16-formula-10"></p>
      </div>
      <p align="center">
        Рис. 3. Квантовая схема оракула функции <i>f(x)</i> = x
      </p>
      <p>
        4. Функция <i>f(x)</i> = x&#175;. Рассмотрим в какие состояния должны
        перейти все возможные базисные состояния регистра:
      </p>
      <div align="center">
        <p className="lecture16-formula-11"></p>
      </div>
      <p>
        Таким образом, матрицу оракула <i>U</i>
        <sub>
          <i>f</i>
        </sub>{" "}
        можно представить в виде:
      </p>
      <div align="center">
        <p className="lecture16-formula-12"></p>
      </div>
      <p>Подобный оператор можно реализовать различными схемами (рис. 4).</p>
      <div align="center">
        <p className="lecture16-formula-13"></p>
      </div>
      <p align="center">
        Рис. 4. Квантовая схема оракула функции <i>f(x)</i> = x&#175;
      </p>
      <p>
        Итак, описав оракулы мы можем вернуться к разработке алгоритма,
        реализующего задачу Дойча. Схема данного алгоритма представлена на рис.
        5.
      </p>
      <div align="center">
        <p className="lecture16-formula-14"></p>
      </div>
      <p align="center">Рис. 5. Схема алгоритма Дойча</p>
      <p>
        Приведем описание как данная схема работает. Применим преобразование
        Адамара к каждому кубиту двукубитовой системы:
      </p>
      <div align="center">
        <p className="lecture16-formula-15"></p>
      </div>
      <p>
        Далее на систему действует оператор <i>U</i>
        <sub>
          <i>f</i>
        </sub>
        :
      </p>
      <div align="center">
        <p className="lecture16-formula-16"></p>
      </div>
      <p>
        Далее по алгоритму применяется преобразование Адамара к первому кубиту,
        и после этого производится его измерение. Рассмотрим оба случая, т.е.
        когда функция является константной или сбалансированной.
      </p>
      <p>
        • В случае если функция является константной, то <i>f</i>(0) = <i>f</i>
        (1). В данном случае после воздействия оператора <i>U</i>
        <sub>
          <i>f</i>
        </sub>{" "}
        первый кубит будет находится либо в состоянии{" "}
        <div align="center">
          <p className="lecture16-formula-17"></p>
        </div>
      </p>
      <p>либо в состоянии </p>
      <div align="center">
        <p className="lecture16-formula-18"></p>
      </div>
      <p>
        При этом воздействие оператора Адамара приведет его в состояния –
        |0&#9002; или –|0&#9002;, соответственно. В любом случае измерение
        покажет, что кубит находится в нулевом состоянии.
      </p>
      <p>
        • В случае если функция является сбалансированной, то <i>f</i>(0) &ne;{" "}
        <i>f</i>
        (1). После воздействия оператора первый кубит будет находится либо в
        состоянии{" "}
        <div align="center">
          <p className="lecture16-formula-19"></p>
        </div>
        , либо в состоянии{" "}
        <div align="center">
          <p className="lecture16-formula-20"></p>
        </div>
        . Последующее воздействие оператора Адамара приведет его в состояния –
        |1&#9002; или –|1&#9002; соответственно, и измерение покажет, что кубит
        находится в единичном состоянии.
      </p>
      <p>
        Далее рассмотрим с вами решения задачи Дойча-Джозы, которая является
        обобщенной задачей Дойча. Данная задача формулируется следующим образом:
        пусть имеется бинарная функция <i>f</i> : &#123;0,1&#125;
        <sup>
          <i>n</i>
        </sup>{" "}
        &rarr; &#123;0,1&#125;, и известно, что данная функция может быть
        константной или сбалансированной. Функция с n входными переменными будет
        сбалансированной если она принимает значение 0 на 2{" "}
        <sup>
          <i>n</i>-1
        </sup>{" "}
        входных наборах и значение 1 на 2{" "}
        <sup>
          <i>n</i>-1
        </sup>{" "}
        входных наборах. Задача Дойча-Джозы, как и задача Дойча, заключается в
        том, что необходимо определить, является ли функция в «черном ящике»
        сбалансированной или константной. По аналогии с предыдущей задачей,
        схема алгоритма Дойча-Джозы будет иметь вид, представленный на рис. 6.
      </p>
      <div align="center">
        <p className="lecture16-formula-21"></p>
      </div>
      <p align="center">Рис. 6. Схема алгоритма Дойча-Джозы</p>
      <p>
        Выполним описание данного алгоритма. Для этого рассмотрим действие
        оператора Адамара на произвольный квантовый регистр |<i>x</i>&#9002;
        <sup>
          <i>n</i>
        </sup>
        :
      </p>
      <div align="center">
        <p className="lecture16-formula-22"></p>
      </div>
      <p>
        В начальный момент времени система (рис. 6) находится в состоянии
        |0&#9002;
        <sup>
          <i>n</i>
        </sup>
        |1&#9002;. Если применить операцию к состоянию{" "}
        <i>
          H<sub>n</sub>
        </i>
        , то мы получим следующий результат
        <div align="center">
          <p className="lecture16-formula-23"></p>
        </div>
        следовательно, для состояния |0&#9002;
        <sup>
          <i>n</i>
        </sup>
        |1&#9002; получим:
      </p>
      <div align="center">
        <p className="lecture16-formula-24"></p>
      </div>
      <p>
        Воздействие оракула{" "}
        <i>
          U<sub>f</sub>
        </i>{" "}
        переведет систему в состояние:
      </p>
      <div align="center">
        <p className="lecture16-formula-25"></p>
      </div>
      <p>
        Если функция <i>f</i>(x) является константной и, учитывая, что
        <div align="center">
          <p className="lecture16-formula-26"></p>
        </div>{" "}
        то первые n кубитов находятся в состоянии
      </p>
      <div align="center">
        <p className="lecture16-formula-27"></p>
      </div>
      <p>
        Повторное применение оператора Адамара к состоянию{" "}
        <i>
          H<sub>n</sub>
        </i>
        |0&#9002;
        <sup>
          <i>n</i>
        </sup>
        приведет к тому, что при измерении (рис. 6) с вероятностью 100% будет
        получено состояние |0&#9002;
        <sup>
          <i>n</i>
        </sup>
        .
      </p>
      <p>
        Если же функция <i>f</i>(x) является сбалансированной, то (-1)
        <sup>
          <i>f</i>(x)
        </sup>{" "}
        нельзя вынести из-под суммы и состояние первых <i>n</i> кубитов можно
        представить как{" "}
      </p>
      <div align="center">
        <p className="lecture16-formula-28"></p>
      </div>
      <p>Отметим, что</p>
      <div align="center">
        <p className="lecture16-formula-29"></p>
      </div>
      <p>
        Если функция <i>f</i>(x) сбалансирована, то число слагаемых в суммах
        выражения
      </p>
      <div align="center">
        <p className="lecture16-formula-30"></p>
      </div>
      <p>
        одинаково, и при повторно применении оператора Адамара векторы |0&#9002;
        <sup>
          <i>n</i>
        </sup>{" "}
        будут отсутствовать. Таким образом в случае сбалансированной функции при
        измерении системы (рис. 6) вероятность получения состояния |0&#9002;
        <sup>
          <i>n</i>
        </sup>{" "}
        будет равно нулю.
      </p>
    </div>
  );
};

export default Lecture16;
