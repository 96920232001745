import React from "react";
import "../theory/lecture.css";

const Lecture14 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Сверхплотное кодирование</h3>
      <p>
        Приведем пример применения перепутанности квантовых состояний. Допустим
        Алиса хочет передать бобу одну из цифр от 0 до 3. Для организации
        передачи информации между Бобом и Алисой каждому из них пересылается
        один из двух кубитов приготовленных в запутанном состоянии, например,
      </p>
      <div align="center">
        <p className="lecture14-formula-1"></p>
      </div>
      <p>
        Пусть Алиса получает первый кубит, а Боб второй. При этом Алиса может
        осуществлять преобразование на своем кубите, а Боб на своем.
      </p>
      <p>
        1. Алиса получает извне два классических бита, которые кодируют цифры от
        0 до 3. В зависимости от значения числа Алиса совершает одно из
        преобразований{" "}
        <i>
          <b>I</b>
        </i>
        ,{" "}
        <i>
          <b>X</b>
        </i>
        ,{" "}
        <i>
          <b>Y</b>
        </i>{" "}
        или{" "}
        <i>
          <b>Z</b>
        </i>
        :
      </p>
      <p>Для цифры 0:</p>
      <div align="center">
        <p className="lecture14-formula-2"></p>
      </div>
      <p>Таким образом, получаем: </p>
      <div align="center">
        <p className="lecture14-formula-3"></p>
      </div>
      <p>Для цифры 1:</p>
      <div align="center">
        <p className="lecture14-formula-4"></p>
      </div>
      <p>
        Таким образом, получаем:
        <div align="center">
          <p className="lecture14-formula-5"></p>
        </div>
      </p>
      <p>Аналогично можно получить преобразования для цифр 2 и 3:</p>
      <div align="center">
        <p className="lecture14-formula-6"></p>
      </div>
      <p>2. Далее Алиса передает свой кубит Бобу.</p>
      <p>3. Боб применяет гейт CNOT к двум запутанным кубитам:</p>
      <div align="center">
        <p className="lecture14-formula-7"></p>
      </div>
      <p>
        4. Далее Боб производит измерение второго кубита. В результате измерений
        он получит состояние |0&#9002; для цифр 0 и 3, состояние |1&#9002; для
        цифр 1 и 2:
      </p>
      <div align="center">
        <p className="lecture14-formula-8"></p>
      </div>
      <p>
        5. Далее Боб применяет{" "}
        <i>
          <b>преобразование Адамара</b>
        </i>{" "}
        к первому кубиту и измеряет его:
      </p>
      <div align="center">
        <p className="lecture14-formula-9"></p>
      </div>
      <p>
        6. Таким образом, выполнив преобразования и измерив два{" "}
        <i>
          <b>кубита</b>
        </i>
        , Боб понимает какую цифру передавала Алиса:
      </p>
      <div align="center">
        <p className="lecture14-formula-10"></p>
      </div>
      <p>
        Исходя из представленного выше описания сврехплотного кодирования можно
        построить квантовую схему (рис. 1).{" "}
      </p>
      <div align="center">
        <p className="lecture14-formula-11"></p>
      </div>
      <p align="center">Рис. 1. Квантовая схема сверхплотного кодирования</p>
      <p>
        На схеме, представленной на рис. 1, передаваемая цифра кодируется в
        двоичном коде <i>x</i>
        <sub>1</sub>
        <i>x</i>
        <sub>0</sub>. При этом один из запутанных{" "}
        <i>
          <b>кубитов</b>
        </i>{" "}
        принадлежит Алисе и в зависимости от передаваемой цифры Алиса применяет
        к нему соответствующее преобразование. После преобразования своего
        кубита Боб применяет операцию распутывания кубитов посредствам операции
        контролируемого{" "}
        <i>
          <b>NOT</b>
        </i>{" "}
        и гейта Адамара{" "}
        <i>
          <b>H</b>
        </i>
        . После этого производится операция измерения двух кубитов.
      </p>
    </div>
  );
};

export default Lecture14;
