import React from "react";
import "../theory/lecture.css";

const Lecture6 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Гейт Z</h3>
      <p>
        Определим сначала действие{" "}
        <i>
          <b>гейтa Z</b>
        </i>{" "}
        на базисные вектора. Потребуем, чтобы он не изменял 0, а 1 переводил в
        -1:
      </p>
      <p align="center">
        |&#936;&#9002; = α|0&#9002; + β|1&#9002; &#8658;
        <i> Z </i>|&#936;&#9002; = α|0&#9002; - β|1&#9002;
      </p>
      <p>
        Тогда действию данного{" "}
        <i>
          <b>гейтa Z</b>
        </i>{" "}
        отвечает матрица:
      </p>
      <p align="center">
        <p className="lecture6-formula-1"></p>
      </p>
      <p>
        Покажем действия{" "}
        <i>
          <b>гейтa Z </b>
        </i>
        на кубит в различных состояниях.
      </p>
      <p align="center">
        <p className="lecture6-formula-2"></p>
      </p>
      <p>
        На сфере Блоха действие{" "}
        <i>
          <b>гейтa Z</b>
        </i>{" "}
        соответствует повороту вектора вокруг оси <i>Z</i> на угол <i>π</i>{" "}
        (рис. 1).
      </p>
      <p align="center">
        <p className="lecture6-formula-3"></p>
      </p>
      <p align="center">
        Рис.1. Действие
        <i>
          <b> гейта Z</b>
        </i>
      </p>
      <p>
        Отметим следующие два свойства{" "}
        <i>
          <b> гейтов X и Z:</b>
        </i>{" "}
      </p>
      <p align="center">
        <i>HXH = Z,</i>
      </p>
      <p align="center">
        <i>HZH = X.</i>
      </p>
      <p>Приведем примеры показывающие данные свойства.</p>
      <p>
        <b>Пример 2.4.</b> Для кубита в состоянии |&#936;&#9002; = α|0&#9002; +
        β|1&#9002; применим операции <i>HXH</i>.
      </p>
      <p align="center">
        <p className="lecture6-formula-4"></p>
      </p>
      <p>
        Как было показано ранее <i> Z </i>|&#936;&#9002; = α|0&#9002; -
        β|1&#9002;, а, следовательно, можно заметить, что <i>HXH = Z</i>.
      </p>
      <p>
        <b>Пример 2.5.</b> Для кубита в состоянии |&#936;&#9002; = α|0&#9002; +
        β|1&#9002; применим операции <i>HZH</i>.
      </p>
      <p align="center">
        <p className="lecture6-formula-5"></p>
      </p>
      <p>
        Как было показано ранее <i> X </i>|&#936;&#9002; = β|0&#9002; -
        α|1&#9002;, а, следовательно, можно заметить, что <i>HZH = X</i>.
      </p>
    </div>
  );
};

export default Lecture6;
