import React from "react";
import "../theory/lecture.css";

const Lecture4 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Гейт Адамара</h3>
      <p>
        Одиночный кубит, по определению, является суперпозицией двух квантовых
        состояний |0&#9002; и |1&#9002; каждое из которых может рассматриваться
        как носитель одного бита классической информации |&#936;&#9002; =
        α|0&#9002; + β|1&#9002;. Чтобы создать состояния суперпозиции,
        используется гейт, называемый{" "}
        <i>
          <b>гейт Адамара (H)</b>
        </i>
        .{" "}
        <i>
          <b>Гейт Адамара</b>
        </i>{" "}
        является одним из наиболее полезных квантовых гейтов. Он задается
        матрицей:
      </p>
      <p align="center">
        <p className="lecture4-formula-1"></p>
      </p>
      <p>Учитывая, что состояния кубита могут быть представлены в виде:</p>
      <p align="center">
        <p className="lecture4-formula-2"></p>
      </p>
      <p>
        Запишем выражения показывающие действия оператора Адамара на кубиты.
      </p>
      <p align="center">
        <p className="lecture4-formula-3"></p>
      </p>
      <p>
        Таким образом, действие оператора <i>H</i> на произвольный кубит можно
        описать формулой:
      </p>
      <p align="center">
        <p className="lecture4-formula-4"></p>
      </p>
      <p>
        В геометрической интерпретации кубита на сфере Блоха можно заметить, что
        в результате действия{" "}
        <i>
          <b> гейта Адамара</b>
        </i>{" "}
        на кубит в состоянии |0&#9002; переводит его в положение между
        состояниями |0&#9002; и |1&#9002;, т.е. в состояние{" "}
        <p align="center">
          <p className="lecture4-formula-5"></p>
        </p>
        Соответственно в результате действия
        <i>
          <b> гейта Адамара</b>
        </i>{" "}
        состояние кубита |1&#9002; переводит его в положение между состояниями
        |0&#9002; и |1&#9002;, только в другой полусфере, т.е. в состояние{" "}
        <p align="center">
          <p className="lecture4-formula-6"></p>
        </p>
        Действие{" "}
        <i>
          <b>гейта Адамара</b>
        </i>{" "}
        на сфере Блоха показано на рис. 1.
        <p align="center">
          <p className="lecture4-formula-7"></p>
        </p>
        <p align="center">
          <i>a</i>
        </p>
        <p align="center">
          <p className="lecture4-formula-8"></p>
        </p>
        <p align="center">
          <i>б</i>
        </p>
        <p align="center">
          Рис.1. Действие
          <i>
            <b> гейта Адамара</b>
          </i>
          : а) начальное состояние кубита |0&#9002;, б) начальное состояние
          кубита |1&#9002;{" "}
        </p>
      </p>
      <p>
        Оператор <i>H</i> является самосопряженным, а, следовательно, обратным к
        себе самому, то его повторное применение к базису Адамара вернет нам
        обычный базис. Другими словами, алгебраические вычисления дают{" "}
        <i>
          H<sup>2</sup> = I
        </i>
        . То есть двукратное применение гейта <i>H</i> возвращает систему в
        исходное положение. Покажем самосопряженность{" "}
        <i>
          <b> гейта Адамара</b>
        </i>{" "}
        на примерах:
      </p>
      <p>
        <b>Пример 2.1.</b> Для кубита в состоянии |0&#9002;:
        <p align="center">
          <p className="lecture4-formula-9"></p>
        </p>
      </p>
      <p>
        <b>Пример 2.2.</b> Для кубита в состоянии |1&#9002;:
        <p align="center">
          <p className="lecture4-formula-10"></p>
        </p>
      </p>
      <p>
        <b>Пример 2.3.</b> Для кубита в состоянии |&#936;&#9002; = α|0&#9002; +
        β|1&#9002;:
        <p align="center">
          <p className="lecture4-formula-11"></p>
        </p>
      </p>
      <p>
        Если у нас имеется система из <i>n</i> кубитов, то применив{" "}
        <i>
          <b> оператор Адамара</b>
        </i>{" "}
        ко всем кубитам индивидуально, мы получим суперпозицию всех 2
        <sup>n</sup>
        состояний:
        <p align="center">
          <p className="lecture4-formula-12"></p>
        </p>
      </p>
    </div>
  );
};

export default Lecture4;
