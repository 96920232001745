import React from "react";
import "../theory/lecture.css";

const Lecture3 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Измерение квантовой системы</h3>
      <p>
        Чтобы получить информацию о состоянии квантовой системы необходимо
        выполнить его измерение. Для проведения измерений мы должны активно
        воздействовать на квантовую систему. В процессе измерения квантовой
        системы в выбранном базисе мы получим один из векторов этого базиса. В
        результате сразу же после измерения состояние квантовой системы
        разрушается, то есть система переходит в состояние, соответствующее
        наблюденному значению.
      </p>
      <p>
        Приведем пример возможных исходов измерения для одно-кубитной системы:
      </p>
      <ul>
        <li>
          Если система находилась в состоянии |0&#9002;, то при ее измерении мы
          получим тоже |0&#9002;.
        </li>
        <li>
          Если система находилась в состоянии |1&#9002;, то при ее измерении мы
          получим тоже |1&#9002;.
        </li>
        <li>
          Если система находилась в состоянии суперпозиции |&#936;&#9002; =
          α|0&#9002; + β|1&#9002;, то в результате ее измерения мы получим
          вектор |0&#9002; с вероятностью |α|<sup>2</sup>, а вектор |1&#9002;, с
          вероятностью |β|<sup>2</sup>.
        </li>
      </ul>
      <p>
        Следует отметить, что вероятность получения в процессе измерения
        конкретного вектора выбранного базиса (|0&#9002; или |1&#9002;) равна
        квадрату модуля скалярного произведения вектора данной системы на этот
        вектор.
      </p>
      <p>
        При этом, в результате измерений невозможно определить значения α и β, а
        также невозможно измерить повторно ту же самую систему, потому что,
        после измерения состояние квантовой системы разрушается. Таким образом,
        для получения максимально достоверной информации о состоянии системы при
        ее измерении необходимо выбирать базис, один из векторов которого
        наиболее близок с измеряемым кубитом.
      </p>
      <p>
        В общем случае, вероятностный процесс измерения квантовой системы
        происходит следующим образом:
      </p>
      <ul>
        <li>
          <p>
            Пусть у нас имеется квантовое состояние системы из <i>n</i>кубитов
            |&#936;&#9002; и стандартный базис пространства состояний системы{" "}
            <i>k</i> = &#123;|0&#9002;, ....,|2<sup>n</sup> - 1&#9002;&#125;
          </p>
          <p align="center">
            <p className="lecture2-formula-1"></p>
          </p>
        </li>
        <li>
          <p>
            При измерении состояния системы по отношению к базису <i>k</i> с
            вероятностью |α
            <sub>
              <i>i</i>
            </sub>
            |<sup>2</sup> результат измерения будет – |<i>i</i>&#9002;.
          </p>
        </li>
        <li>
          <p>
            После измерения квантовая система будет находится в состоянии – |
            <i>i</i>&#9002;.
          </p>
        </li>
        <li>
          <p>
            После измерений амплитуды состояний отличных от |<i>i</i>&#9002;
            будут равны нулю: α
            <sub>
              <i>k</i>
            </sub>{" "}
            = 0 для <i>k</i>&#8800; <i>i</i>.
          </p>
        </li>
      </ul>
      <p>Рассмотрим пример измерения 2-кубита.</p>
      <p>
        <b>Пример 1.1:</b> Выполним измерение следующего 2-кубита.
      </p>
      <p align="center">
        |&#936;&#9002; = α<sub>0</sub>|00&#9002; + α<sub>1</sub>|01&#9002; + α
        <sub>2</sub>|10&#9002; + α<sub>3</sub>|11&#9002;
      </p>
      <p align="center">
        α<sub>0</sub> = 0.3; α<sub>1</sub> = 0.1; α<sub>2</sub> = 0.9; α
        <sub>3</sub> = 0.3
      </p>
      <p>При измерении данного 2-кубита возможны четыре варианта:</p>
      <ul>
        <li>
          С вероятностью 0.09 получится значение 00 и 2-кубит перейдет в
          состояние |00&#9002;.
        </li>
        <li>
          С вероятностью 0.01 получится значение 01 и 2-кубит перейдет в
          состояние |01&#9002;.
        </li>
        <li>
          С вероятностью 0.81 получится значение 10 и 2-кубит перейдет в
          состояние |10&#9002;.
        </li>
        <li>
          С вероятностью 0.09 получится значение 11 и 2-кубит перейдет в
          состояние |11&#9002;.
        </li>
      </ul>
      <p>
        В некоторых задачах требуется измерить состояние лишь некоторых кубитов
        в большом <i>n</i>-кубите. Приведем пример подобной ситуации для
        3-кубита.
      </p>
      <p>
        <b>Пример 1.2:</b> Выполним измерение первых двух битов кубита, но не
        будем трогать третий бит.
      </p>

      <p align="center">
        |&#936;&#9002; = α<sub>0</sub>|000&#9002; + α<sub>1</sub>|001&#9002; + α
        <sub>2</sub>|010&#9002; + α<sub>3</sub>|011&#9002; + α<sub>5</sub>
        |101&#9002; + α<sub>6</sub>|110&#9002;
      </p>
      <p align="center">
        α<sub>0</sub> = 0.3; α<sub>1</sub> = -0.6; α<sub>2</sub> = -0.1; α
        <sub>3</sub> = -0.7; α<sub>5</sub> = 0.1; α<sub>6</sub> = -0.2
      </p>
      <p>
        Видно, что в заданном нами примере отсутствуют состояния |100&#9002; и
        |111&#9002;, то есть их коэффициенты α<sub>4</sub> и α<sub>7</sub> равны
        0.
      </p>
      <p>
        Так как нам необходимо измерить только два первых кубита, то возможны
        четыре варианта исхода: 00, 01, 10, 11. После измерения первые два
        кубита получат определенные значения, а значение третьего кубита не
        будет фиксировано.
      </p>
      <ul>
        <li>
          Вероятность наблюдения значения 00 будет определятся как α<sub>0</sub>
          <sup>2</sup> + α<sub>1</sub>
          <sup>2</sup> = 0.09 + 0.36 = 0.45, при этом новое состояние системы
          будет следующим:
          <div align="center">
            <p className="lecture3-formula-1"></p>
          </div>
          <p align="center">
            α<sub>0</sub> = 0.3; α<sub>1</sub> = -0.6;
          </p>
        </li>
        <li>
          Вероятность наблюдения значения 01 будет определятся как α<sub>2</sub>
          <sup>2</sup> + α<sub>3</sub>
          <sup>2</sup> = 0.01 + 0.49 = 0.5, при этом новое состояние системы
          будет следующим:
          <div align="center">
            <p className="lecture3-formula-2"></p>
          </div>
          <p align="center">
            α<sub>2</sub> = -0.1; α<sub>3</sub> = -0.7;
          </p>
          <p>
            Стоит отметить, что для нормализации состояния третьего не
            наблюдаемого кубита необходимо коэффициенты этих двух состояний
            разделить на корень квадратный из вероятности появления данного
            исхода.
          </p>
        </li>
        <li>
          Вероятность наблюдения значения 10 будет определятся как α<sub>5</sub>
          <sup>2</sup> = 0.01, при этом новое состояние системы будет
          |101&#9002;.
        </li>
        <li>
          Вероятность наблюдения значения 11 будет определятся как α<sub>6</sub>
          <sup>2</sup> = 0.04, при этом новое состояние системы будет
          |110&#9002;.
        </li>
        <p>
          Заметьте, учитывая особенности нашего 3-кубита (отсутствие состояний
          |101&#9002; и |110&#9002;), в последних двух случаях вероятность того,
          что третий бит соответственно принимает значения 1 и 0, равна 1.
        </p>
      </ul>
      <p>
        <b>Пример 1.3:</b> Выполним измерение последних двух кубитов, но не
        будем трогать первый кубит.
      </p>
      <p align="center">
        |&#936;&#9002; = α<sub>0</sub>|000&#9002; + α<sub>1</sub>|001&#9002; + α
        <sub>2</sub>|010&#9002; + α<sub>3</sub>|011&#9002; + α<sub>5</sub>
        |101&#9002; + α<sub>6</sub>|110&#9002;
      </p>
      <p align="center">
        α<sub>0</sub> = 0.3; α<sub>1</sub> = -0.6; α<sub>2</sub> = -0.1; α
        <sub>3</sub> = -0.7; α<sub>5</sub> = 0.1; α<sub>6</sub> = -0.2
      </p>
      <ul>
        <li>
          Вероятность наблюдения значения 00 будет определятся как |α
          <sub>0</sub>|<sup>2</sup> = 0.09, при этом новое состояние системы
          будет |000&#9002;.
        </li>
        <li>
          Вероятность наблюдения значения 01 будет определятся как α<sub>0</sub>
          <sup>2</sup> + α<sub>5</sub>
          <sup>2</sup> = 0.36 + 0.01 = 0.37, при этом новое состояние системы
          будет следующим:
        </li>
        <div align="center">
          <p className="lecture3-formula-3"></p>
        </div>
        <p align="center">
          α<sub>5</sub> = 0.1; α<sub>1</sub> = -0.6
        </p>
        <li>
          Вероятность наблюдения значения 10 будет определятся как α<sub>2</sub>
          <sup>2</sup> + α<sub>6</sub>
          <sup>2</sup> = 0.01 + 0.04 = 0.05, при этом новое состояние системы
          будет следующим:
        </li>
        <div align="center">
          <p className="lecture3-formula-4"></p>
        </div>
        <p align="center">
          α<sub>2</sub> = 0.1; α<sub>6</sub> = -0.2
        </p>
        <li>
          Вероятность наблюдения значения 11 будет определятся как α<sub>6</sub>
          <sup>2</sup> = 0.04, при этом новое состояние системы будет
          |011&#9002;.
        </li>
      </ul>
    </div>
  );
};

export default Lecture3;
