import React from "react";
import "../theory/lecture.css";

const Lecture18 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Квантовое преобразование Фурье</h3>
      <p>
        Квантовое преобразование Фурье (КПФ) – это аналог дискретного
        преобразования Фурье (ДПФ), которое применяется к вектору амплитуд
        квантовых состояний. Квантовое преобразование Фурье широко применяется
        во многих квантовых алгоритмах, в частности, в алгоритме Шора.
      </p>
      <p>
        Квантовое преобразование Фурье осуществляет преобразование квантового
        состояния следующим образом:
      </p>
      <div align="center">
        <p className="lecture18-formula-1"></p>
      </div>
      <p>
        При измерении состояния после преобразования Фурье с вероятностью |
        <i>F</i>(<i>y</i>)|<sup>2</sup> мы получим состояние |<i>y</i>&#9002;.
        ДПФ осуществляет преобразование функции с периодом <i>r</i> в функцию,
        которая имеет нулевые значения на всех частотах не кратных 1/<i>r</i>.
        Применив квантовое преобразование Фурье к функции <i>f</i>(<i>x</i>) с
        периодом <i>r</i> мы получим функцию <i>F</i>(<i>y</i>), которая будет
        равно нулю, кроме значений кратных <i>N</i>/<i>r</i>. Т.е. проведя
        измерение мы получим результат кратный <i>N</i>/<i>r</i>.{" "}
      </p>
      <p>Квантовое преобразование Фурье определяется следующим образом:</p>
      <div align="center">
        <p className="lecture18-formula-2"></p>
      </div>
      <p>
        В общем случае квантовая схема квантового преобразования Фурье
        представлена на рис. 1.
      </p>
      <div align="center">
        <p className="lecture18-formula-3"></p>
      </div>
      <p align="center">Рис. 1. Схема квантового преобразования Фурье</p>
      <p>
        Следует отметить, что схема меняет порядок кубитов. В сехеме,
        представленной на рис. 1 используются унитарные операторы поворота{" "}
        <i>
          R<sub>k</sub>
        </i>
        , которые можно определить как:
      </p>
      <div align="center">
        <p className="lecture18-formula-4"></p>
      </div>
      <p>В общем случае выражение для n-ой линии можно выражением:</p>
      <div align="center">
        <p className="lecture18-formula-5"></p>
      </div>
    </div>
  );
};

export default Lecture18;
