import React from "react";
import "../theory/lecture.css";

const Lecture13 = () => {
  return (
    <div className="div-lecture">
      <h3 align="center">Перепутанные состояния двух кубитов. Базис Белла</h3>
      <p>
        Рассмотрим два незапутанных кубита. Незапутанность двух кубитов
        подразумевает, что измерение первого кубита не влияет на результат
        измерения второго кубита. Зададим их состояния:
      </p>
      <div align="center">
        <p className="lecture13-formula-1"></p>
      </div>
      <p>Состояние пары кубитов получается перемножение одиночных состояний:</p>
      <div align="center">
        <p className="lecture13-formula-2"></p>
      </div>
      <p>
        В данном случае вероятность событий |00&#9002;, |01&#9002;, |10&#9002; и
        |11&#9002; равна произведению вероятностей его составляющих –{" "}
        <i>&alpha;</i>
        <sub>0</sub>
        <i>&beta;</i>
        <sub>0</sub>, <i>&alpha;</i>
        <sub>0</sub>
        <i>&beta;</i>
        <sub>1</sub>, <i>&alpha;</i>
        <sub>1</sub>
        <i>&beta;</i>
        <sub>0</sub> и <i>&alpha;</i>
        <sub>1</sub>
        <i>&beta;</i>
        <sub>1</sub>, соответственно.
      </p>
      <p>
        Перепутанное состояние двух кубитов – это состояние, при котором
        измерение одного из кубитов однозначно определяет состояние второго
        кубита. Например, если в результате измерения одного кубита получилось,
        состояние |0&#9002;, то измерение другого кубита однозначно даст
        значение |0&#9002;, а если в результате измерения одного кубита
        получилось, состояние |1&#9002;, то измерение другого кубита также даст
        значение |1&#9002;. В данном примере амплитуды вероятности групп
        |01&#9002; и |10&#9002; равны нулю. Такое состояние системы двух кубитов
        можно записать следующим образом:
      </p>
      <div align="center">
        <p className="lecture13-formula-3"></p>
      </div>
      <p>
        Для понимания специфики подобных состояний можно подробно рассмотреть
        процессы измерения, например, для состояний:
      </p>
      <div align="center">
        <p className="lecture13-formula-4"></p>
      </div>
      <p>
        Сначала рассмотрим процесс измерения двухкубитного состояния
        |&#936;&#9002; = α<sub>00</sub>|00&#9002; + α<sub>01</sub>|01&#9002; + α
        <sub>10</sub>|10&#9002; + α<sub>11</sub>|11&#9002; . Допустим, что
        сначала мы проводим измерение первого кубита. В ходе проведения
        измерения первого кубита мы получим состояние |0&#9002; в том случае,
        если мы попадем в группы |00&#9002; и |01&#9002;. Вероятность такого
        события будет составлять |α<sub>00</sub>|<sup>2</sup> + |α<sub>01</sub>|
        <sup>2</sup>. При этом первый кубит перешел в состояние |0&#9002;, а
        состояния |10&#9002; и |11&#9002; становятся нереализуемы.
      </p>
      <p>
        После получения этого результата двухкубитное состояние выглядит так:
      </p>
      <div align="center">
        <p className="lecture13-formula-5"></p>
      </div>
      <p>Теперь рассмотрим процесс измерения двухкубитного состояния</p>
      <div align="center">
        <p className="lecture13-formula-6"></p>
      </div>
      <p>
        {" "}
        В данном случае при измерении реализуемы только два состояния:
        |01&#9002; и |10&#9002;. Если мы проведем измерение первого кубита, то
        реализуемой остается только одна группа: |01&#9002; или |10&#9002;.
        Отсюда следует, что состояние второго кубита тоже определится, хотя его
        мы пока не провели его измерение. Например, если при измерении первого
        кубита мы получим состояние |&#936;<sub>1</sub>&#9002; = |0&#9002; тогда
        реализуемой остаётся только группа |01&#9002;, то есть второй кубит
        переходит в определенной состояние – |&#936;<sub>2</sub>&#9002; =
        |1&#9002;. Если же при измерении первого кубита мы получим состояние
        |&#936;<sub>1</sub>&#9002; = |1&#9002; тогда реализуемой остаётся только
        группа |10&#9002;, то есть второй кубит переходит в определенной
        состояние – |&#936;<sub>2</sub>&#9002; = |0&#9002;. Также можно описать
        процессы измерения других перепутанных состояний.
      </p>
      <p>
        Аналогично обстоит дело и с более сложными системами: трёхкубиными,
        четырёхкубитными и так далее. Измерение одного кубита всегда выводит его
        из запутанности с остальными кубитами и приводит в одно из двух чистых
        базисных состояний. Квантовое состояние оставшейся части системы кубитов
        скачкообразно изменяется строго определённым образом. Если же измеряемый
        кубит не запутан с прочими кубитами системы, то при его измерении с
        остальными кубитами ничего не происходит.
      </p>
      <p>
        На рис. 1 представлены способы реализации состояний Белла системы двух
        кубитов.
      </p>
      <div align="center">
        <p className="lecture13-formula-7"></p>
      </div>
      <p align="center">Рис. 1. Примеры реализации состояний Белла</p>
    </div>
  );
};

export default Lecture13;
